<template>
    <MainTitle title="Edition"></MainTitle>
    <div class="corps">
        <div class="container">
            <div class="left">
                <h4>Identité</h4>
                <table class="id">
                    <tr>
                        <td>
                            <label for="name">Nom</label>
                        </td>
                        <td>
                            <input type="text" id="name" v-model="lastnameInput">
                            
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <label for="first">Prenom</label>
                        </td>
                        <td>
                            <input type="text" v-model="firstnameInput" id="first">
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <p>UID:</p>
                        </td>
                        <td>
                            <p>{{UID}}</p>  
                        </td>
                    </tr>
                    
                </table>

                

                <button v-if="modifiedFirstnameOrLastname" @click="modifyRing()">Sauvegarder le profile</button>
                <button v-if="modifiedFirstnameOrLastname === false" style="background-color: grey;">Sauvegarder le profile</button>


            </div>

            <div class="right">
                <h4>Portes Dispo</h4>
                <div style="border: 1px solid black; width: 85.2%; display: flex; justify-content: space-around">
                    <p>Porte</p>
                    <p>Acces</p>
                </div>
                <div class="listeP">
                    <div style=" width: 100%; display: flex; justify-content: space-between;" class="porteItem" v-for="porte, index in portes" v-bind:key="porte">   
                        <label style="width: 50%;" for="check">{{porte.name}}</label>
                        <input style="width: 25%;" type="checkbox" id="checkbox" v-bind:checked="porte.authorized" v-on:click="changeAutori(porte.id, index)">
                        <p style="color: red; width: 25%;" v-if="porte.authorized === false || porte.authorized === undefined">Interdit</p>
                        <p style="color: green; width: 25%;" v-if="porte.authorized">Autorisé</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    
</template>

<script>
import MainTitle from './MainTitle.vue'

export default {
    name: "EditProfile",
    components: { 
        MainTitle 
    },
    props: {

    },
    data() {
        return {
            firstname: "",
            lastname: "",
            firstnameInput: "",
            lastnameInput: "",
            UID: null,
            
            portes: [
                
             
            ]
        };
    },

    created(){
        this.$http.get(`rings/${this.$route.params.id}`)
            .then(response => {
                if(response.data.error != undefined && response.data.error == false && response.data.result){
                    console.log(response.data)
                    this.firstname = response.data.result.firstname
                    this.lastname = response.data.result.lastname
                    this.UID = response.data.result.UID
                }
            })
            .catch(error => {
                if(error.response && error.response.status === 404){
                    this.$router.push("/rings")
                }
                
            })

        this.$http.get(`doors`)
            .then(response => {
                if(response.data.error != undefined && response.data.error == false && response.data.result instanceof Array){
                    response.data.result.forEach(door => {
                        if(door.authorizedRings instanceof Array){

                            let newDoor = {name: door.name, id: door._id, authorized: false, authorizedRings: door.authorizedRings}

                            if(door.authorizedRings.filter(e => e.UID === this.UID).length > 0){
                                console.log("Autorisé sur la porte " + door.name)
                                newDoor.authorized = true
                            }else{
                                console.log("Pas Autorisé sur la porte " + door.name)
                                newDoor.authorized = false

                            }
                            this.portes.push(newDoor)

                        }

                    });
                    console.log(this.portes)
                }
            })
        

    },

    methods: {
        modifyRing: function(){
            console.log('tret')
            this.$http.patch(`rings/${this.$route.params.id}`, {
                firstname: this.firstnameInput != this.firstname ? this.firstnameInput : null,
                lastname: this.lastnameInput != this.lastname ? this.lastnameInput : null
            })
                .then(response => {
                    // TODO: show pop up
                    if(response.data.error != undefined && response.data.error == false && response.data.result){
                        this.firstname = response.data.result.firstname
                        this.lastname = response.data.result.lastname
                        this.UID = response.data.result.UID
                    }
                })
        },

        changeAutori(id, index){
                let authorizedRings;
                if(this.portes[index].authorized){
                    authorizedRings = this.removeRingIdFromDoor(this.portes[index].authorizedRings, this.$route.params.id)
                } else {
                    authorizedRings = this.portes[index].authorizedRings
                    authorizedRings.push(this.$route.params.id)
                }
                console.log(authorizedRings)
                
                this.$http.patch(`doors/${id}`, {
                    authorizedRings
                })
                .then(response => {
                    if(response.data.error == false && response.data.result){
                        let door = response.data.result
                        if(door.authorizedRings instanceof Array){

                            let newDoor = {name: door.name, id: door._id, authorized: false, authorizedRings: door.authorizedRings}

                            if(door.authorizedRings.filter(e => e.UID === this.UID).length > 0){
                                console.log("Autorisé sur la porte " + door.name)
                                newDoor.authorized = true
                            } else{
                                console.log("Pas Autorisé sur la porte " + door.name)
                                newDoor.authorized = false

                            }

                            this.portes[index] = newDoor;
                     
                        }

                    }
                })
                
        },

        removeRingIdFromDoor(rings, id){
            let newRings = []
            rings.forEach((ring) => {
                if(ring._id != id){
                    newRings.push(ring._id)
                }
            })
            return newRings
        }
    },

    watch: {
        firstname: function(val){
            if(val){
                this.firstnameInput = this.firstname
            }
        },
        lastname: function(val){
            if(val){
                this.lastnameInput = this.lastname
            }
        }
    },

    computed: {
        modifiedFirstnameOrLastname(){
            return this.lastnameInput != this.lastname || this.firstnameInput != this.firstname
        }
    }

}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .corps{
        margin-top: 2%;
        width: 50%;
        margin-left: 25%;
        border: 2px solid black;
        
        height: 220px;
        border-radius: 15px;
    }

    p{
        margin: 0;
    }

    .container{
        display: flex;
    }

    
    .porteItem{
        width: 100%;
    }

    button:hover{
        cursor: pointer;
    }

    input[type="text"]{
        border: none;
        border-bottom: 1px solid black;
    }

 

    button{
        width: 80%;
        height: 35px;
        margin-left: 10%;
        margin-top: 20px;
        background-color: rgb(135, 236, 135);
        border: 1px solid black;
        border-radius: 15px;
    }

    .listeP{
        height: 100px;
        width: 80%;
        overflow-x: hidden;
        overflow-y: auto;
        border: 1px black solid;
        padding-left: 12px;
        padding-right: 10px;
    }

    .right{
        width: 63%;
        margin-left: 5%;

    }

    .left{
        margin-left: 8%;
        widows: 20%;
        margin-right: 2%;
    }

    /* Works on Firefox */
    .listeP {
        scrollbar-width: thin;
        scrollbar-color: white black;
    }

    /* Works on Chrome, Edge, and Safari */
    .listeP::-webkit-scrollbar {
        width: 6px;
    }

    .listeP::-webkit-scrollbar-track {
        background: white;
    }

    .listeP::-webkit-scrollbar-thumb {
        background-color: white;
        border-radius: 20px;
        border: solid 1px black;
    }

</style>
