<template>
  <MainTitle title="Add A Ring"/>
 

  <div class="corpsActiveAdd" id="active">
    <div class="textBox">
      <div>
        <label for="firstname">FirstName  </label>
        <input v-model="firstname" type="text" id="firstname">
      </div>

      <div>
        <label for="lastname">LastName  </label>
        <input v-model="lastname" type="text" id="lastname">
      </div>
    </div>
    <button @click="pop()">Create a new SecuRing</button>

  </div>



  <div class="overlayPop" v-if="popSearch === true">
    <div class="popTag">
      <i @click="popSearch = false; UID = null" class="fa-solid fa-square-xmark" style="cursor: pointer; display: flex; justify-content: flex-end; margin-right: 10px; margin-top: 5px;"></i>
      <h2 style="text-align:center; margin-bottom: 20px;">{{firstname}}'s Ring</h2>

      <h3 v-if="!UID" style="text-align:center; margin-bottom: 40px;">Waiting For the NFC Tag...</h3>
      <h3 v-if="UID" style="color: green; text-align:center; margin-bottom: 40px;">Tag Received</h3>
      <div style="display: flex; width: 20%; margin-left: 40%; text-align: center;">
        
        <span>UID : {{UID}}</span>
        
      </div>
      <button v-on:click="createRing()" style="cursor: pointer;">Save The NFC Tag</button>
    </div>
  </div>

</template>

<script>
import MainTitle from './MainTitle.vue';

export default { 
  name: 'AddForm',
  components: {
    MainTitle
  },
  props: {
    
  },

  data(){
    return{
      popSearch: false,
      firstname: null,
      lastname: null,
      UID: null
    }
  },

  methods: {
    pop(){
      if(this.firstname && this.lastname){
        this.popSearch = true
        let evtSource = new EventSource('http://164.132.227.158:3000/api/events/');
        evtSource.onmessage = this.onRing
      }
    },

    onRing(e){
      let data = JSON.parse(e.data)
      this.UID = data.ringScanned
      console.log(this.UID)
    },

    createRing(){
      this.$http.post("rings", {firstname: this.firstname, lastname: this.lastname, UID: this.UID})
        .then(response => {
          if(response.data.error === false && response.data.result){
            this.$router.push("/")
          }
        })
        .catch(error => {
          if(error.response && error.response.status === 409){
            // If Ring is already in database
            console.log(error.response.data.message)
          }
        })
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
