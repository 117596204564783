<template>
    <div class="card">
        <div class="tableLine container">
            <div class="cel1 cel" ><i class="fa-regular fa-user">{{firstname}} {{lastname}}</i></div>
            <div class="cel2 cel"><i class="fa-regular fa-id-badge"> {{UID}}</i></div>
            <div class="cel3 cel"><router-link style="color: black;" :to="'/rings/' + id"><i class="fa-regular fa-pen-to-square"> Edit Permissions</i></router-link></div>
            <div class="cel5 cel"><i class="fa-regular fa-clipboard"> History</i></div>
            <div class="cel4 cel"><i class="fa-regular fa-circle-xmark" @click="deleteRing(id)"> Delete Ring</i></div>
            
        </div>
    </div>
</template>

<script>
export default { 
  name: 'BagueCard',
  props: {
    firstname: String,
    lastname: String,
    UID: Number,
    id: String
  },

    
  methods:{
    deleteRing(id){
        this.$parent.activeDelete.id = id;
        this.$parent.activeDelete.name = this.firstname + " " + this.lastname;
        this.$parent.popSearch = true;
        
    }
  }

  
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.card{
    width: 95%;
    padding-left: 5%;
    padding-top: 15px;
    height: 40px;
    border-bottom: 1px solid #005700;
    text-align: left;
}

.container{
    display: flex;
}
.tableLine{
    width: 100%;
}


.cel{
    overflow: hidden;
}
.cel1{
    width: 40%;
    
}
.cel2{
    width: 10%;
}
.cel3{
    width: 25%;
}
.cel4{
    width: 15%;
}

.cel5{
    width: 10%;
}

i {
    cursor: pointer;
   
}

</style>
