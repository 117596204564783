<template>
  <div class="console">
    <h2>Console:</h2>
    <div class="logs">

    </div>
  </div>
</template>

<script>
export default { 
  name: 'HistoryConsole',
  props: {
      
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.console{
  margin-top: 20px;
  width: 70%;
  border: 2px black solid;
  border-radius: 15px;
  height: 600px;
  margin-left: 15%;
}

.console h2{
  border-bottom: 1px solid black;
  padding-bottom: 15px;
}
</style>
