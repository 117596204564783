import RingListBody from './components/RingListBody.vue';
import AddForm from './components/AddForm.vue';
import EditProfile from './components/EditProfile.vue';
import HistoryConsole from './components/HistoryConsole.vue';
import Login from './components/LoginPage.vue';

import {createRouter, createWebHashHistory} from 'vue-router'
const routes = [
    { path: '/', redirect: '/rings'},
    { path: '/rings', component: RingListBody},
    { path: '/add', component: AddForm},
    { path: '/rings/:id', component: EditProfile},
    { path: '/console', component: HistoryConsole},
    { path: '/login', component: Login}
  ]

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
  })

  export default router;