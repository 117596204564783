<template>
    <div class="entete container">
    <h1>SecuRing</h1>
    <div class="icon-bar container">
      <router-link to="/" style="color: black;"><i v-on:click="onClickActive('activeRing')" class="fa-solid fa-ring"> Actives Rings</i></router-link>
      <router-link to="/add" style="color: black;"><i class="fa-solid fa-circle-plus"> Add A Ring</i></router-link>
    </div>
  </div>
</template>

<script>


export default { 
  name: 'HautPage',
  props: {
    
  },

  methods: {
    onClickActive(str) {
      this.$emit('changeActive', str);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

*{
  background-color:#9CDB9C;
}

.entete{
  display: absolute;
  border-bottom: 2px solid #413b05;
  height: 100px;
  padding: 0;
  margin: 0;
  
}

.container {
  display: flex;
  justify-content: space-around;
  
}

.icon-bar {
  padding-top: 30px;
  width: 30%;
  
  font-size: 20px;
  
}

.icon-bar i:hover {
  cursor: pointer;
}

</style>
