<template>

  <MainTitle title="Actives Rings"/>

  <div class="searchBar">
    <input v-model="search" type="text" placeholder="Search a ring..."/>
    <i class="fa-solid fa-magnifying-glass"></i>
  </div>

  <div class="corpsActive" id="active">
    <BagueCard @changeActive="changeActive" v-for="index in filtered" v-bind:key="index" :firstname="index.firstname" :lastname="index.lastname" :UID="index.UID" :id="index._id" />
  </div>


  <div class="overlayPop" v-if="popSearch === true">
    <div class="popTag">
      <i @click="popSearch = false" class="fa-solid fa-square-xmark" style="cursor: pointer; display: flex; justify-content: flex-end; margin-right: 10px; margin-top: 5px;"></i>
      <h2 style="text-align:center; margin-bottom: 20px;">Delete : {{activeDelete.name}}</h2>
      <button style="height: 50px; background-color: #cb7878; cursor: pointer;" @click="deleteRing(activeDelete.id)">Delete the Ring</button>
    </div>
  </div>

</template>

<script>
import BagueCard from './BagueCard.vue';
import MainTitle from './MainTitle.vue';

export default { 
  name: 'RingListBody',
  components: {
    BagueCard,
    MainTitle
  },

  props: {
    title: String
  },

  data() {
    return{
      search : '',
      listCard: [],
      popSearch: false,
      activeDelete: {}
    }

  },

  methods: {
    getRings(){
      this.$http.get("rings")
        .then(response => {
            this.listCard = response.data.result
            console.log(response.data.result)
            console.log(this.listCard)
        })
    },
    deleteRing(id){
      this.$http.delete(`rings/${id}`)
        .then(response => {
          if(response.data.error != undefined && response.data.error == false){
            this.popSearch = false
            this.getRings();
          }
        })
    }

  },

  computed: {
    filtered(){
      return this.listCard.filter((el) => {
        return el.firstname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || el.lastname.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
      })
    }
  },

  created(){
    console.log("Created")
    //let token = localStorage.getItem("token")
    this.getRings();
  }
  

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
