
<template>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet">
  <HautPage @changeActive="changeActive"/>
  
  

    
  <router-view></router-view>
  
 


</template>


<script>

import HautPage from './components/HautPage.vue';





export default {
  name: 'App',
  components: {
    HautPage,
  
},
  
  data(){
    return {
      activepage: ''
    }
  },

  methods: {
    changeActive(str){
      this.activepage = str
    }

  }
}



</script>










<style>

*{
  font-family: 'Roboto', sans-serif;
}

i {
  font-family: 'Roboto', sans-serif;
  
}

body {
  padding: 0;
  margin: 0;
  background-color: white;
  
}


.overlayPop{
  position: fixed; 
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(11, 169, 37, 0.31);
}

.popTag{
  margin-top: 15%;
  width: 40%;
  height: 230px;
  margin-left: 30%;
  background-color: white;
  border: 2px solid black;
  border-radius: 15px;

}

.popTag input{
  width: 70%;
  margin-left: 15px;
}

.popTag label{
  margin-left: 40px;
}

.popTag button{
  width: 50%;
  margin-left: 25%;
  margin-top: 2%;
  height: 25px;
  background: rgba(62, 225, 68, 0.451);
  border-radius: 5px;
  border: 1px solid black;
}

.textBox {
  margin-top: 35px;
  display: flex;
  justify-content: space-around;
  font-size: 20px;
}

.textBox input {
  border: none;
  border-bottom: 1px solid black;
}

.corpsActiveAdd {
  margin-top: 1%;
  width: 50%;
  margin-left: 25%;
  height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  border: 3px solid black;
  border-radius: 10px;
}

.corpsActiveAdd button{
  width: 80%;
  height: 30%;
  margin-top: 30px;
  background-color: white;
  border-radius: 10px;
}

.corpsActiveAdd button:hover{
  width: 80%;
  height: 30%;
  margin-top: 30px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}


.searchBar{
  margin-left: 10%;
  width: 80%;
  display: flex;
  justify-content: flex-end;
}

.searchBar input{
  margin-right: 15px;
  border: none;
  border-bottom: 1px black solid;
}

.searchBar i:hover{
  cursor: pointer;
  
}

.searchBar:hover{
  font-size: 19px;
  transition: 0.2s;
}

.searchBar{
  transition: 0.2s;
}


.corpsActive {
  margin-top: 1%;
  width: 80%;
  margin-left: 10%;
  height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  border: 3px solid black;
  border-radius: 10px;
}

/* Works on Firefox */
.corpsActive {
  scrollbar-width: thin;
  scrollbar-color: white black;
  
}

/* Works on Chrome, Edge, and Safari */
.corpsActive::-webkit-scrollbar {
  width: 10px;
  border: 2px black solid;
}

.corpsActive::-webkit-scrollbar-track {
  background: black;
}

.corpsActive::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 20px;
  border: solid 2px black;
}




</style>

