import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // <---
import axios from 'axios'


let axiosApi = axios.create({
    baseURL: 'http://164.132.227.158:3000/api'
})

axiosApi.interceptors.request.use(function(config){
    let token = localStorage.getItem('token')
    if(token !=  undefined && token != "" && config.url !== "user/login"){
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

axiosApi.interceptors.response.use(function(response){
    return response
}, function(error){
    console.log(error)
    if(error.response && error.response.status == 401){
        router.push("/login")
    }
    return Promise.reject(error)
}

)

const app = createApp(App)
app.config.globalProperties.$http = axiosApi

app.use(router).mount('#app')