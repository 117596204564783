<template>
    <MainTitle title="Login"/>
    <div class="corpsActiveAdd" id="active">
        <div class="textBox">
        <div>
            <label for="firstname">Login  </label>
            <input v-model="username" type="text" id="firstname" placeholder="Username">
        </div>

        <div>
            <label for="lastname">Password  </label>
            <input v-model="password" type="password" id="lastname" placeholder="Password">
        </div>
        </div>
        <button v-on:click="login()">Login</button>

    </div>
 
</template>

<script>

import MainTitle from './MainTitle.vue';
export default { 
  name: 'LoginPage',
  components: {
    MainTitle
  },

  props: {
    title: String
  },

  data() {
    return{
      username: null,
      password: null
    }

  },

  methods: {
    login(){
      this.$http.post("user/login", {username: this.username, password: this.password})
        .then(response => {
          if(response.data.error != undefined && response.data.error === false && response.data.token != undefined) {
            localStorage.setItem("token", response.data.token)
            this.$router.push("/")
          }
        })
        .catch(error => {
          if(error.response && error.response.status === 401){
            console.log("Login failed")
          }
        })
    }


  }

  

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
