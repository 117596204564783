<template>
    <h1>{{title}}</h1>
</template>

<script>
export default { 
  name: 'BagueCard',
  props: {
      title: String
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    text-align: center;
    margin-top: 2%;
}
</style>
